import * as React from "react"

import "@fontsource/abhaya-libre"
import "@fontsource/montserrat"
import './index.module.css';

const description = 'BBCoin (bbc) is a cryptocurrency that powers peer-to-peer transactions for the adult entertainment industry. It utilizes a hybird Proof of Size / Proof of Stake (PoSS) consensus algorithm to allow validators to add new BBB (big black blocks) to the chain.'

const marketStats = {
  'Market cap': '$6.9 billion',
  'Volume': '$743.2 million',
  'Circulating supply': '69.5 million',
  'Typical hold time': '6.9 days',
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Home Page</title>
      <h1 style={headingStyles}>
        BBCoin.
        <br/>
        <span style={headingAccentStyles}>The biggest thing in cryptocurrency, period. </span>
        <span role="img" aria-label="Party popper emojis">
         🍆
        </span>
      </h1>
      <p style={paragraphStyles}>
        {description}
      </p>
      <h2 style={h3styles}>Stats</h2>
      <ul style={listStyles}>
      {Object.entries(marketStats).map(([key, value]) =>
        (<li>{key}: {value}</li>))
      }
      </ul>
      <b><a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Learn more.</a></b>
    </main>
  )
}

// styles
const pageStyles = {
  padding: 48,
}
const headingStyles = {
  fontFamily: "-apple-system, Abhaya Libre, serif",
  fontWeight: '800',
  marginTop: 0,
  maxWidth: 320,
  fontSize: '64px',
}
const h3styles = {
  fontFamily: "-apple-system, Abhaya Libre, serif",
  fontWeight: '800',
  maxWidth: 320,
}
const headingAccentStyles = {
  fontSize: '24px',
  color: "#663399",
}
const paragraphStyles = {
  fontFamily: "-apple-system, Montserrat, serif",
  marginBottom: 48,
  maxWidth: '300px',
  lineHeight: '1.8',
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  fontFamily: "-apple-system, Montserrat, serif",
  marginBottom: 96,
  paddingLeft: 0,
  lineHeight: '1.8',
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

export default IndexPage
